import { LitElement, html, css, classMap } from 'https://assets.kiipu.com/libs/lit/3.1.3/lit-all.min.js';
import { stylesString } from './button-styles.js';

export class KpcButton extends LitElement {
  static styles = [
    css([stylesString])
  ];

  static properties = {
    type: { type: String },
  }

  constructor() {
    super();
    this.type = 'primary';
  }
  
  render() {
    return html`
      <button class="button ${this.type}">按钮</button>
    `;
  }
}

if (!window.customElements.get('kpc-button')) {
  window.customElements.define('kpc-button', KpcButton);
}
