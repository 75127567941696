import { LitElement, html, css, classMap, styleMap } from 'https://assets.kiipu.com/libs/lit/3.1.3/lit-all.min.js';
import { stylesString } from './avatar-styles.js';

export class KpcAvatar extends LitElement {
  static styles = [
    css([stylesString])
  ];

  static properties = {
    src: { type: String },
    href: { type: String },
    preview: { type: Boolean },
    size: { type: String },
    variant: { type: String }
  }

  constructor() {
    super();
    this.src = '';
    this.href = '';
    this.preview = false;
    this.size = 'medium';
    this.variant = 'circular';
  }

  render() {
    const variant = ['rounded', 'square'].indexOf(this.variant) > -1 && this.variant;
    const presetSize = ['small', 'large', 'medium'].indexOf(this.size) > -1 && this.size;
    const wh = !presetSize ? this.size : null;
    const style = styleMap({ width: wh, height: wh });
    const classProps = {
      [`size--${presetSize}`]: presetSize && presetSize !== 'medium',
      [`variant--${variant}`]: variant,
    }

    if (this.src === '<loading>') {
      return html`<div class=${classMap({
        loading: true,
        ...classProps,
      })} style=${style}></div>`;
    }

    const avatarElem = html`<img class=${classMap({
      avatar: true,
      ...classProps,
    })} style=${style} src="${this.src}" />`;

    return (this.href && !this.preview)
      ? html`<a href="${this.href}" target="_blank">${avatarElem}</a>`
      : html`${avatarElem}`;
  }
}

if (!window.customElements.get('kpc-avatar')) {
  window.customElements.define('kpc-avatar', KpcAvatar);
}