import { LitElement, html, css, styleMap } from 'https://assets.kiipu.com/libs/lit/3.1.3/lit-all.min.js';
import { stylesString } from './picture-styles.js';

export class KpcPicture extends LitElement {
  static styles = [
    css([stylesString])
  ];

  static properties = {
    src: { type: String },
    href: { type: String },
    preview: { type: Boolean },
    width: { type: String },
    height: { type: String }
  }

  constructor() {
    super();
    this.src = '';
    this.href = '';
    this.preview = false;
  }

  render() {
    if (this.src === '<loading>') {
      return html`<div class="loading"><img src="https://assets.kiipu.com/img/icon_light.svg" /></div>`;
    }

    const pictureElem = html`<img class="picture" style=${styleMap({
      width: this.width,
      height: this.height,
    })} src="${this.src}" />`;

    return (this.href && !this.preview)
      ? html`<a href="${this.href}" target="_blank">${pictureElem}</a>`
      : html`${pictureElem}`;
  }
}

if (!window.customElements.get('kpc-picture')) {
  window.customElements.define('kpc-picture', KpcPicture);
}
