import { LitElement, html, css, nothing } from 'https://assets.kiipu.com/libs/lit/3.1.3/lit-all.min.js';
import { stylesString } from './time-styles.js';

export class KpcTime extends LitElement {
  static styles = [
    css([stylesString])
  ];

  static properties = {
    datetime: { type: Number },
    variant: { type: String },
    color: { type: String },
    diff: { type: Boolean },
  }

  constructor() {
    super();
    this.datetime = null;
    this.variant = 'body2';
    this.color = 'secondary';
    this.diff = false;
  }

  // isValidDate(val) {
  //   return !isNaN(new Date(val).getTime());
  // }

  formatDatetime(val) {
    const date = new Date(val);
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
  }

  getDiffTime(dateTime) {
    // 用毫秒表示分钟、小时、天、周、月
    let minute = 1000 * 60;
    let hour = minute * 60;
    let day = hour * 24;
    let week = day * 7;
    let month = day * 30;
  
    // 获取当前时间并转换为时间戳，方便计算
    let timestamp_current = new Date().getTime();
   
    // 将传入的时间格式字符串解析为Date对象
    let _date = new Date(dateTime);
   
    // 将Date对象转换为时间戳，方便计算
    let timestamp_input = _date.getTime();
   
    // 计算当前时间与传入的时间之间相差的时间戳
    let timestamp_diff = timestamp_current - timestamp_input;
   
    // 计算时间差共有多少个分钟
    let minC = timestamp_diff / minute;
    // 计算时间差共有多少个小时
    let hourC = timestamp_diff / hour;
    // 计算时间差共有多少个天
    let dayC = timestamp_diff / day;
    // 计算时间差共有多少个周
    let weekC = timestamp_diff / week;
    // 计算时间差共有多少个月
    let monthC = timestamp_diff / month;
   
    /*if (monthC >= 1 && monthC < 4) {
      return parseInt(monthC) + '个月前';
    } else if (weekC >= 1 && weekC < 4) {
      return parseInt(weekC) + '周前';
    } else */if (dayC >= 1 && dayC < 7) {
      return parseInt(dayC) + '天前';
    } else if (hourC >= 1 && hourC < 24) {
      return parseInt(hourC) + '小时前';
    } else if (minC >= 10 && minC < 60) {
      return parseInt(minC) + '分钟前';
    } else if ((timestamp_diff >= 0) && (minC < 10)) {
      return '刚刚';
    } else {
      return ((new Date().getFullYear() != _date.getFullYear()) ? _date.getFullYear() + '年' : '') + (_date.getMonth() + 1) + '月' + _date.getDate() + '日';
    }
  }
  
  render() {
    const formatDatetime = this.formatDatetime(this.datetime);
    // return this.isValidDate(this.datetime) ? html`<time datetime=${this.datetime}>${this.formatDatetime(this.datetime)}</time>` : nothing;
    return html`<kpc-typography variant=${this.variant} color=${this.color}><time datetime=${this.datetime} title=${this.diff ? formatDatetime : nothing}>${this.diff ? this.getDiffTime(this.datetime) : formatDatetime}</time></kpc-typography>`;
  }
}

if (!window.customElements.get('kpc-time')) {
  window.customElements.define('kpc-time', KpcTime);
}
