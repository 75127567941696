import { LitElement, html, css, classMap, styleMap } from 'https://assets.kiipu.com/libs/lit/3.1.3/lit-all.min.js';
import { stylesString } from './typography-styles.js';

export class KpcTypography extends LitElement {
  static styles = [
    css([stylesString])
  ];

  static properties = {
    nowrap: { type: Boolean },
    variant: { type: String },
    color: { type: String },
    align: { type: String },
  }

  constructor() {
    super();
    this.nowrap = false;
    this.variant = 'body1';
    this.color = 'primary';
    this.align = 'inherit';
  }

  render() {
    const presetColor = ['primary', 'secondary'].indexOf(this.color) > -1 && this.color;
    const style = styleMap({ color: !presetColor ? this.color : null });
    const align = ['left', 'center', 'right'].indexOf(this.align) > -1 && this.align;
    return html`<p class=${classMap({
      typography: true,
      [`variant--${this.variant}`]: true,
      [`color--${presetColor}`]: presetColor,
      'no-wrap': this.nowrap,
      [`align--${align}`]: align,
    })} style=${style}><slot></slot></p>`;
  }
}

if (!window.customElements.get('kpc-typography')) {
  window.customElements.define('kpc-typography', KpcTypography);
}
