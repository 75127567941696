import { LitElement, html, css, classMap, styleMap } from 'https://assets.kiipu.com/libs/lit/3.1.3/lit-all.min.js';
import { stylesString } from './link-styles.js';

export class KpcLink extends LitElement {
  static styles = [
    css([stylesString])
  ];

  static properties = {
    href: { type: String },
    variant: { type: String },
    color: { type: String },
    preview: { type: Boolean }
  }

  constructor() {
    super();
    this.href = '';
    this.variant = 'body2';
    this.color = 'action';
    this.preview = false;
  }

  render() {
    const presetColor = ['primary', 'secondary', 'action'].indexOf(this.color) > -1 && this.color;
    const linkStyle = styleMap({ color: !presetColor ? this.color : null });
    return (this.href && !this.preview)
      ? html`<a class=${classMap({
        link: true,
        [`color--${presetColor}`]: presetColor && presetColor !== 'action'
      })} target="_blank" href=${this.href} style=${linkStyle}><kpc-typography variant=${this.variant} color=${this.color}><slot /></kpc-typography></a>`
      : html`<kpc-typography variant=${this.variant} color=${this.color}><slot /></kpc-typography>`;
  }
}

if (!window.customElements.get('kpc-link')) {
  window.customElements.define('kpc-link', KpcLink);
}
